import React from "react"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

const Content = ({ content }) => {

  return (
    <section className="section section--privacy-content">
      <div className="wrapper" >
        <div className="section--privacy-content__container" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </section>
  )
}

export default Content
