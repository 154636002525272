import React from "react"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

const Header = ({ title }) => {

  return (
    <section className="section section--privacy-hero">
      <div className="wrapper">
        <h1 className="section--privacy-hero__title">{ title }</h1>
      </div>
    </section>
  )
}

export default Header
